<template>
<Calendar
  class-name="select-mode"
  :remarks="remarks"
  :tileContent="tileContent"
  monFirst
  :selectDate="selectDate"
/>
</template>

<script>
import { ref } from 'vue'
import Calendar from 'mpvue-calendar'
export default {
  components: {
    Calendar,
  },
  setup() {
    const selectDate = ref('2024-3-13')
    const remarks = ref({'2024-3-13': '测试'})
    const tileContent = ref({
      '2024-3-1': {className:'tip class',content:'测试'}
    })
    return {
      selectDate,
      remarks,
      tileContent
    }
  }
};
</script>

<style>
.select-mode {
  width: 100%;
}
</style>